.dg-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  overflow: auto;
  text-align: unset;
  max-width: none;
  width: 450px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
  padding: 30px 20px 20px 30px;
  font-family: "Planc"
}

.dg-tooltip .arrow {
  border-top: 13px solid transparent;
  border-right: 15px solid white;
  border-bottom: 13px solid transparent;
}

.dg-create-pin-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  overflow: auto;
  text-align: unset;
  max-width: none;
  width: 370px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
  padding: 30px 20px 20px 30px;
  font-family: "Planc"
}

.dg-create-pin-tooltip .arrow {
  border-top: 13px solid transparent;
  border-right: 15px solid white;
  border-bottom: 13px solid transparent;
}

