.submission-field {
    border: 1px solid var(--neutral200);
    border-radius: 8px;
    padding: 1.875em;
    margin-top: 0.5em;
}

@media screen and (max-width: 992px) {
    .submission-field {
        padding: 1.25em;
    }
}

.submission-field__header {
    display: flex;
    justify-content: space-between;
}

.submission-field__header-btn {
    border: none;
    background: none;
    color: var(--purplePrimary);
    font-weight: 700;
    display: flex;
    align-items: center;
}

.submission-field__header-btn::after {
    width: 24px;
    height: 24px;
    margin-left: .625em;
}

.submission-field__header-btn--edit::after {
    content: url("../edit.svg");
}

.submission-field__header-btn--add::after {
    content: url("../plus-circle.svg");
}

.submission-field__header__title {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.submission-field__header__required {
    color: var(--redPrimary);
    margin-left: .25em;
}

@media screen and (max-width: 992px) {
    .submission-field__header__title {
        display: flex;
        align-items: start;
        font-size: 1.25em;
    }
}