.card-academic-paper-link {
  color: var(--black);
  text-decoration: none;
}

.card-academic-paper {
  border: 1px solid #e3dfed;
  border-radius: 8px;
  white-space: normal;
}

.card-header {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.edit-button {
  display: none;
}

.card-academic-paper:hover .edit-button {
  display: flex;
}

.card-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.card-image {
  background-color: #d9d9d9;
  aspect-ratio: 0.7;
  border-radius: 8px;
}

.show-more {
  color: var(--purplePrimary);
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  text-decoration: none;
  cursor: pointer;
}
