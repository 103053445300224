.mentors-scroll {
  overflow: hidden;
  white-space: nowrap;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 30px;
  padding: 30px 20px;
}

.mentors-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}