.dg-switch .form-check-input {
  margin-top: 0;
  height: 24px;
  width: 44px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-color: var(--neutral200);
  border: none;
  box-shadow: none;
}

.dg-switch .form-check-input:checked {
  background-color: var(--purplePrimary);
  border: none;
  box-shadow: none;
}

.dg-switch .form-check-input:focus {
  border: none;
  box-shadow: none;
}
