/* Primary Button */
.btn-dg-primary {
  color: #120e16;
  stroke: #120e16;
  background-color: var(--yellowPrimary);
  border: 0px;
  border-radius: 100px;
  border: 1.5px solid transparent;
}

.btn-dg-primary:hover,
.btn-dg-primary:active {
  color: #120e16 !important;
  stroke: #120e16 !important;
  background-color: #ff9635 !important;
  box-shadow: none !important;
  border: 1.5px solid transparent !important;
}

.btn-dg-primary:focus,
.btn-dg-primary:focus-visible {
  color: #120e16;
  stroke: #120e16;
  background-color: var(--yellowPrimary);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  outline: none;
}

/* Secondary Button */
.btn-dg-secondary {
  color: #ffffff;
  stroke: #ffffff;
  background-color: var(--purplePrimary);
  border: 0px;
  border-radius: 100px;
  border: 1.5px solid transparent;
}

.btn-dg-secondary:hover,
.btn-dg-secondary:active {
  color: #ffffff !important;
  stroke: #ffffff !important;
  background-color: #3e1f5f !important;
  box-shadow: none !important;
  border: 1.5px solid transparent !important;
}

.btn-dg-secondary:focus,
.btn-dg-secondary:focus-visible {
  color: #ffffff;
  stroke: #ffffff;
  background-color: var(--purplePrimary);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  outline: none;
}

/* Tertiary Button */
.btn-tertiary {
  color: #120e16;
  stroke: #120e16;
  background-color: transparent;
  border: 1.5px solid #120e16;
  border-radius: 100px;
}
.btn-tertiary:active,
.btn-tertiary:hover {
  color: #ffffff !important;
  stroke: #ffffff !important;
  background-color: var(--purplePrimary) !important;
  border: 1.5px solid var(--purplePrimary) !important;
}

/* Quaternary Button */
.btn-quaternary {
  color: white;
  stroke: white;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  border-radius: 100px;
}

.btn-quaternary:active,
.btn-quaternary:hover {
  color: #120e16 !important;
  stroke: #120e16 !important;
  background-color: #ffffff !important;
  border: 1.5px solid #ffffff !important;
}

/* Button Sizes */
.btn-xs {
  padding: 8px 12px 6px;
  font-weight: 700;
  font-size: 12px;
}

.btn-sm {
  padding: 12px 20px 8px;
  font-weight: 700;
  font-size: 15px;
}

.btn-medium {
  padding: 12px 24px 10px;
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
}

.btn-lg {
  padding: 16px 32px 12px;
  font-weight: 700;
  font-size: 19px;
}

.btn-medium-ic {
  padding: 9px 18px 6px;
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
}

.btn-sm-ic {
  padding: 7px 14px 4px 14px;
  font-weight: 700;
  font-size: 15px;
}

.btn-lg-ic {
  padding: 8px 22px 11px 22px;
  font-weight: 700;
  font-size: 17px;
}

.btn-slider-button-light {
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid var(--black);
  border-radius: 30px;
  text-align: center;
  background-color: #ffffff00;
  stroke: var(--black);
}

.btn-slider-button-light:active,
.btn-slider-button-light:hover {
  background-color: var(--purplePrimary) !important;
  border: 1px solid var(--purplePrimary) !important;
  stroke: white !important;
}

.btn-slider-button-dark {
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid white;
  border-radius: 30px;
  text-align: center;
  background-color: #ffffff00;
  stroke: white;
}

.btn-slider-button-dark:active,
.btn-slider-button-dark:hover {
  background-color: white !important;
  border: 1px solid white !important;
  stroke: var(--purplePrimary) !important;
}

.btn-dg-close {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--neutral50);
  stroke: var(--black);
  text-align: center;
  padding: 0;
}

.btn-dg-close:active,
.btn-dg-close:hover {
  background-color: var(--purplePrimary) !important;
  stroke: white !important;
}
