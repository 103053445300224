.home-search-bar .container {
  background-color: white;
  border: 1px solid var(--neutral100);
  border-radius: 40px;
  position: relative;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
}

.form-control:focus {
  box-shadow: none;
}

.mentor-search-dropdown {
  padding: 16px 24px 11px 20px;
  background-color: white;
  border: 1px solid var(--neutral100);
  border-radius: 8px;
  transition: all 0.25s;
}

.mentor-search-dropdown:hover {
  background-color: var(--neutral50);
  border: 1px solid var(--neutral50);
}

.mentor-search-dropdown-menu {
  margin-top: 20px !important;
  left: -20px !important;
}