.card-project {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-project .project-image {
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 1/0.74;
  object-fit: cover;
}

.card-project .card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-project .version-view {
  border: 1.5px solid #60269E;
  border-radius: 4px;
  width: 24px;           
  height: 24px;
  font-size: 12px;
  font-weight: 700;
  color: var(--purplePrimary);
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  padding: 3px 0px 0px 0px;
}
