.success {
  border-left: 8px solid var(--greenPrimary);
  background: var(--greenLight);
  padding: 16px 24px;
  border-radius: 8px;
}

.error {
  border-left: 8px solid var(--redPrimary);
  background: var(--redLight);
  padding: 16px 24px;
  border-radius: 8px;
}
