.nav-tabs {
  border: 0px;
  gap: 40px;
}

.nav-tabs > .nav-link {
  color: #120e16;
  padding: 10px 20px 7.5px 20px;
  border: 0px;
  margin: 0px 25px;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;

  padding: 0px;
  margin: 0px;
}

.nav-tabs > .nav-link:focus {
  color: var(--purplePrimary);
}

.nav-tabs > .nav-link:hover {
  color: var(--purplePrimary);
  border-color: transparent;
}

.nav-tabs > .nav-link.active {
  background: transparent;
  color: var(--purplePrimary);
  border-color: #60269e;
}

.profile-tab > .nav-link {
  color: white;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;

  padding-bottom: 10px;
  margin: 0px;

  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
}

.profile-tab > .nav-link:focus {
  color: var(--yellowPrimary);
}

.profile-tab > .nav-link:hover {
  color: var(--yellowPrimary);
  border-color: transparent;
}

.profile-tab > .nav-link.active {
  background: transparent;
  color: var(--yellowPrimary);
  border-color: var(--yellowPrimary);
}

/* Mobile Section */

.mobile.nav.nav-tabs {
  border: 0px;
  gap: 0px;
  background-color: var(--purplePrimary);
  flex-wrap: nowrap;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobile.nav.nav-tabs::-webkit-scrollbar {
  display: none;
}

.mobile.nav.nav-tabs > .nav-link {
  color: white;
  border: 0px;
  margin: 0px 25px;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;
  padding: 20px 20px 15px;
  margin: 0px;
  flex-shrink: 0;
  width: max-content;
}

.mobile.nav.nav-tabs > .nav-link:focus {
  color: var(--yellowPrimary);
}

.mobile.nav.nav-tabs > .nav-link:hover {
  color: var(--yellowPrimary);
  border-color: transparent;
}

.mobile.nav.nav-tabs > .nav-link.active {
  background: transparent;
  color: var(--yellowPrimary);
  border-color: var(--yellowPrimary);
}
