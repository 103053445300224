@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1146px;
  }
}

:root {
  /* Primary */
  --purplePrimary: #60269e;
  --yellowPrimary: #f6bf53;
  --bluePrimary: #4c91ff;
  --redPrimary: #f65a27;
  --greenPrimary: #5fc975;

  /* Light */
  --purpleLight: #efe9f5;
  --yellowLight: #fffae6;
  --blueLight: #eefaff;
  --redLight: #fff4f4;
  --greenLight: #f0fff0;

  /* Medium */
  --purpleMedium: #bb9ddc;
  --yellowMedium: #ffdc96;
  --blueMedium: #8ed2fb;
  --redMedium: #f1906e;
  --greenMedium: #b0ebad;

  /* Dark */
  --purpleDark: #3e1f5f;
  --yellowDark: #6c4a21;
  --blueDark: #004283;
  --redDark: #992e00;
  --greenDark: #2f5537;

  /* Neutrals */
  --neutral50: #efedf5;
  --neutral100: #e3dfed;
  --neutral200: #c2becc;
  --neutral300: #8a839d;
  --neutral500: #504b5f;

  /* Typography & Links */
  --black: #120e16;

  /* Gradients */
  --gradientPrimary: linear-gradient(120deg, #60269e 0%, #96489d 127%);
  --gradientYellow: linear-gradient(120deg, #4c91ff 0%, #8ed2fb 127%);
  --gradientBlue: linear-gradient(120deg, #4c91ff 0%, #8ed2fb 100%);
  --gradientRed: linear-gradient(120deg, #f65a27 0%, #f1906e 100%);
  --gradientGreen: linear-gradient(120deg, #5fc975 0%, #8ed2fb 110%);
  --gradientBlack: linear-gradient(120deg, #120e16 0%, #fff4f4 110%);
}

:focus-visible {
  outline: none;
}

/* main {
  min-height: 80vh;
} */

a {
  color: #120e16;
  text-decoration: none;
  stroke: #120e16;
}

a:focus {
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
}

a:hover {
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
  border-color: transparent;
}

a.active {
  background: transparent;
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
  border-color: #60269e;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  padding: 0;
}

.row > * {
  padding: 0;
}

.row {
  margin: 0px;
}

.card-mentor {
  border: 1px solid var(--neutral100);
  border-radius: 8px;
  padding: 12px 12px 16px;
  transition: padding 0.5s, color 0.5s, background-color 0.5s;
  color: var(--black);
  position: relative;
  z-index: 1;
}

.card-mentor:hover {
  background-color: white;
  color: var(--purplePrimary);
  z-index: 2;
  position: initial;
}

.dg-dropdown {
  border: 1px solid var(--neutral200) !important;
  border-radius: 5px !important;
  padding: 16px 20px 12px !important;
  background-color: transparent !important;
  color: var(--black) !important;
  stroke: var(--black) !important;
}

.dg-dropdown:focus {
  box-shadow: none;
  border: 1px solid var(--purplePrimary) !important;
  color: var(--purplePrimary) !important;
  stroke: var(--purplePrimary) !important;
}

.dropdown-item.active {
  color: var(--purplePrimary) !important;
  background-color: transparent;
  stroke: var(--purplePrimary) !important;
}

.dropdown-item:hover {
  color: var(--purplePrimary);
  background-color: var(--neutral50);
  stroke: var(--purplePrimary);
}

.fullscreen-dialog .modal-dialog {
  max-width: none;
  margin: 0px;
  height: 100%;
}

.fullscreen-dialog .modal-dialog .modal-content {
  background-color: #241c2c;
  height: 100%;
  border-radius: 0px;
}

.dropzone {
  text-align: center;
  background: #ffffff;
  border: 1px dashed var(--neutral300);
  border-radius: 4px;
  padding: 30px 20px;
}

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}
.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}

.dg-radio-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px;
}

.dg-radio-button > .form-check-input {
  background: #ffffff;
  border: 2px solid var(--neutral500);
  width: 20px;
  height: 20px;
  margin: 0px !important;
}

.dg-radio-button > .form-check-input:checked {
  border: 2px solid var(--purplePrimary);
  background: url("public/icons/TickForCheckbox.svg") no-repeat 2px 3px
    var(--purplePrimary);
}

.dg-radio-button > .form-check-input:focus {
  box-shadow: none;
}

.dg-radio-button > .form-check-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}

.message-list-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: var(--neutral500);
  font-size: 15px;
}

.react-pdf__Page {
  margin: 30px auto;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.dg-dropdown-input {
  border: none !important;
  padding: 0px !important;
  background-color: transparent !important;
  color: var(--black) !important;
}
.dg-dropdown-input:active:focus {
  box-shadow: none;
}
.dg-dropdown-input:focus {
  box-shadow: none;
}

.dg-dropdown-input-box {
  border: none;
  box-shadow: none;
}

.dg-dropdown-input.dropdown-toggle.btn.btn-primary {
  width: 100%;
}

.dg-dropdown-input.dropdown-toggle.btn.btn-primary::after {
  display: none;
}

.dg-dropdown-input-menu {
  width: 340px;
  max-height: 290px;
  overflow: auto;
  display: flex !important;
  flex-direction: column;
  gap: 16px;
  padding: 20px !important;
}

.dg-dropdown-input-menu.show {
  margin-top: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dashboard-notification-card {
  display: flex;
  border-bottom: 1px solid #e3dfed;
  padding: 12px 0px;
  gap: 18px;
  flex-wrap: wrap;
  stroke: var(--black);
  color: var(--black);
}

.dashboard-notification-card:hover {
  stroke: var(--purplePrimary);
  color: var(--purplePrimary);
}

.dashboard-notification-card-button {
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  justify-content: flex-end;
}

.boxed-card {
  color: var(--black);
  transition: transform 0.5s, box-shadow 0.5s;
}

.boxed-card:hover {
  color: var(--purplePrimary);
  transform: scale(1.05);
}

.dg-tooltip-small .tooltip-inner {
  background-color: var(--purplePrimary);
  color: white;
  overflow: auto;
  text-align: unset;
  max-width: none;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
  font-family: "Planc";
}

.dg-mobile-nav-tab {
  border: 0px;
  gap: 30px;
  flex-wrap: nowrap;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0px 20px;
}

.dg-mobile-nav-tab::-webkit-scrollbar {
  display: none;
}

.dg-mobile-nav-tab > .nav-link {
  color: #120e16;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;
  padding-bottom: 15px !important;
  margin: 0px;
  flex-shrink: 0;
  width: max-content;
}

.dg-mobile-nav-tab > .nav-link:focus {
  color: var(--purplePrimary);
}

.dg-mobile-nav-tab > .nav-link:hover {
  color: var(--purplePrimary);
  border-color: transparent;
}

.dg-mobile-nav-tab > .nav-link.active {
  background: transparent;
  color: var(--purplePrimary);
  border-color: #60269e;
}

.header-icon-light {
  cursor: pointer;
  stroke: black;
  color: black;
}

.header-icon-light:hover {
  stroke: var(--purplePrimary);
  color: var(--purplePrimary);
}

.header-icon-dark {
  cursor: pointer;
  stroke: white;
  color: white;
}

.header-icon-dark:hover {
  stroke: var(--yellowPrimary);
  color: var(--yellowPrimary);
}

.header-icon-active {
  cursor: pointer;
  stroke: var(--yellowPrimary);
  color: var(--yellowPrimary);
}

/*
  Resets
*/

.text-content {
  overflow: hidden;
}

.text-content ul {
  padding: 0px;
  margin: 0 0 24px 0;
}

.text-content li {
  padding: 0px 12px 12px 24px !important;
}

.text-content li:before {
  content: "•";
  vertical-align: top;
  margin-right: 6px;
  margin-top: -2px;
}

.text-content figcaption,
.text-content p {
  margin-bottom: 25px;
  padding-right: 55px;
}

.text-content h3 {
  margin-bottom: 30px;
}

.text-content img {
  margin: 2rem auto;
  max-width: 100%;
  height: auto;
}

.text-content figure {
  margin-bottom: 2rem;
}

.text-content figure img {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .text-content p {
    padding-right: 0;
  }
}

.messages-left-view {
  height: 100%;
  overflow: auto;
  position: relative;
}

.messages-left-view-tabs {
  height: 65px;
  border-bottom: 1px solid var(--neutral100);
}

.messages-left-view-tab-element {
  height: 100%;
  display: flex;
  justify-content: center;
  border-bottom: none;
  font-weight: 400;
  color: var(--black);
  font-weight: 700;
}

.messages-left-view-tab-element > div {
  margin: auto;
}

.scroll-view-hidden::-webkit-scrollbar {
  display: none;
}

.carousel-container {
  overflow: hidden;
  position: relative;
  border-color: #4b5563;
  width: 100%;
  height: 25px;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-card > img {
  height: 100%;
  width: 100%;
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 80px;
  gap: 16px;
  width: 200%;
  height: 100%;
  animation: slide 40s linear infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 55px;
}

.wrapper {
  position: absolute;
  display: flex;
}

.wrapper .image {
  margin: 0 25px;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.profile-nav::-webkit-scrollbar {
  display: none;
}

.profile-nav > .nav-item > .nav-link {
  color: white;
  padding: 8px 0;
  stroke: white;
}

.profile-nav > .nav-item > .nav-link.active {
  border-bottom: 3px solid var(--yellowPrimary);
  color: var(--yellowPrimary);
  stroke: var(--yellowPrimary);
}

.profile-nav > .nav-item > .dropdown-menu.show {
  width: 270px;
}

@media (max-width: 768px) {
  .profile-nav > .nav-item > .nav-link {
    color: #241c2c;
    padding: 8px 0;
    stroke: #241c2c;
    width: max-content;
  }

  .profile-nav > .nav-item > .nav-link.active {
    border-bottom: 3px solid var(--purplePrimary);
    color: var(--purplePrimary);
    stroke: var(--purplePrimary);
  }

  .profile-nav > .nav-item > .dropdown-menu.show {
    width: 160px;
  }
}

.mentor-category-item {
  flex-direction: column;
  width: fit-content;
  color: var(--black);
  cursor: pointer;
  align-items: center;
  gap: 6px;
}

.mentor-category-item.active {
  color: var(--purplePrimary) !important;
  font-weight: 700;
}

.tooltip {
  --bs-tooltip-bg: var(--purplePrimary);
  margin-bottom: 4px;
}

.podcast-embed-container {
  display: "flex";
  justify-content: "center";
}

.podcast-embed-container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 480/270;
}

.explore-content img {
  max-width: 100%;
  display: block;
}

.homepage-chip {
  display: flex;
  padding: 12px 32px 12px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  background: var(--White, #fff);
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.07);
  transition: all 0.5s;
}

.homepage-chip:hover {
  box-shadow: none;
  transition: all 0.5s;
}
