.navbar-dark {
  color: #ffffff;
  background-color: var(--purplePrimary);
}

.navbar-dark .nav-link {
  color: #ffffff;
  stroke: #ffffff;
}

.navbar-dark .nav-link:hover {
  color: var(--yellowPrimary);
  stroke: var(--yellowPrimary);
}

.navbar-dark .nav-link.active {
  color: var(--yellowPrimary);
  stroke: var(--yellowPrimary);
}

.navbar-light {
  color: var(--black);
  background-color: #ffffff;
  border-bottom: 1px solid var(--neutral50);
}

.navbar-light .nav-link {
  color: var(--black);
}

.navbar-light .nav-link:hover {
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
}

.navbar-light .nav-link.active {
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-explore {
  position: static !important;
}

.dropdown-explore .dropdown-menu {
  top: 99px;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
  padding: 45px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #d3d1d9;
  position: absolute;
  margin: 0px !important;
}

.dropdown-explore .dropdown-menu .dropdown-item {
  padding: 0px;
}

.dropdown-explore .dropdown-menu .dropdown-item:hover {
  color: var(--purplePrimary);
  stroke: var(--purplePrimary);
  background-color: transparent;
}

.tooltip.show {
  opacity: 1 !important;
}

.header-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  overflow: auto;
  text-align: unset;
  max-width: none;
  width: 380px;
  margin-top: 13px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
}

.header-tooltip-profile .tooltip-inner {
  background-color: white;
  color: black;
  overflow: auto;
  text-align: unset;
  max-width: none;
  width: 300px;
  margin-top: 13px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
}

.header-tooltip-notifications .tooltip-inner {
  background-color: white;
  color: black;
  overflow: auto;
  text-align: unset;
  max-width: none;
  width: 360px;
  margin-top: 13px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
}