.personal-info {
    margin-top: 1.875em;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .personal-info {
        align-items: flex-start;
    }
}

.personal-info__image {
    width: 8em;
    aspect-ratio: 1;
}

.personal-info__image__self {
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

.personal-info__text {
    margin-left: 1.875em;
}

.personal-info__text__country {
    color: var(--neutral300);
}

.personal-info--alt {
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.personal-info__image--alt {
    width: 6.5em;
    position: relative;
}

.personal-info__image--alt:hover::after {
    content: url("../../public/photograph-machine.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(18, 14, 22, 0.5);
    width: 6.5em;
    aspect-ratio: 1;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    cursor: pointer;
}

.personal-info__image__card {
    width: 100%;
    background-color: var(--neutral50);
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 1.25em;
}

@media screen and (max-width: 992px) {
    .personal-info__image__card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .personal-info__image__card__icon {
        display: none;
    }
}

.personal-info__image__card__copy {
    margin-left: 1.875em;
    max-width: 20.625em;
}

@media screen and (max-width: 992px) {
    .personal-info__image__card__copy {
        margin-top: 1em;
    }
}

.personal-info__image__card__example {
    margin-left: 1em;
}

@media screen and (max-width: 992px) {
    .personal-info__image__card__example {
        margin-left: 0;
        display: flex;
    }
}

.personal-info__input {
    width: 100%;
    margin-top: 2.125em;
}

.personal-info__input > label {
    font-weight: 600;
    font-size: 17px;
}

.personal-info__input > input {
    border-radius: 4px;
    border: 1px solid var(--neutral200);
    width: 100%;
    padding: .625em 1.25em;
    height: 2.5em;
}

.personal-info__input__wrapper {
    display: flex;
}

@media screen and (max-width: 992px) {
    .personal-info__input__wrapper {
        flex-direction: column;
        width: 100%;
    }
}

.personal-info__input__dropdown__control {
    height: 2.5em;
    margin-right: 1.875em;
}

@media screen and (max-width: 992px) {
    .personal-info__input__dropdown__control {
        margin-right: 0;
    }
}

.personal-info__input {
    display: flex;
    flex-direction: column;
}

.personal-info__footer {
    margin-left: auto;
    margin-top: 1.875em;
}

.personal-info__footer__btn {
    border: none;
    background: none;
}

.personal-info__footer__btn--submit {
    color: var(--black);
    background-color: var(--yellowPrimary);
    padding: 0.75em 1.5em;
    border-radius: 100px;
}

.personal-info__footer__btn--decline {
    color: var(--purplePrimary);
    margin-right: 1.625em;
}

@media screen and (max-width: 992px) {
    .personal-info__text__name {
        font-size: 1.125rem;
    }
}