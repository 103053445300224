@font-face {
  font-family: Planc;
  src: url("public/fonts/Planc-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Planc;
  src: url("public/fonts/Planc-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Planc";
  stroke: var(--black);
  overscroll-behavior-y: none;
}

input {
  font-family: var(--bs-body-font-family) !important;
}

/* Fonts */

.f-label-s {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
}

.f-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}

.f-label-l {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.f-label-xl {
  font-weight: 500;
  font-size: 19px;
  line-height: 1.2px;
}

.f-paragraph {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
}

.f-h6 {
  font-weight: 700;
  font-size: 17px;
  line-height: 1.2;
}

.f-h5 {
  font-weight: 700;
  font-size: 19px;
  line-height: 1.2;
}

.f-h4 {
  font-weight: 700;
  font-size: 23px;
  line-height: 1.2;
}

.f-h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
}

.f-h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  letter-spacing: 0.2px;
}

.f-h1 {
  font-weight: 700;
  font-size: 45px;
  letter-spacing: 0.5px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .f-label-xl {
    font-size: 20px;
  }

  .f-paragraph {
    font-size: 18px;
  }

  .f-h6 {
    font-size: 18px;
  }

  .f-h5 {
    font-size: 21px;
  }

  .f-h4 {
    font-size: 26px;
  }

  .f-h3 {
    font-weight: 800;
    font-size: 32px;
  }

  .f-h2 {
    font-weight: 800;
    font-size: 45px;
    letter-spacing: 0.2px;
  }

  .f-h1 {
    font-weight: 800;
    font-size: 58px;
    letter-spacing: 0.5px;
  }
}