.message-list-element {
  padding: 12px 0px;
  gap: 8px;
  position: relative;
  cursor: pointer;
  background: transparent;
  border-left: 2px solid transparent;
}

.message-list-element-selected {
  padding: 12px 0px;
  gap: 8px;
  position: relative;
  cursor: pointer;
  background: var(--neutral50);
  border-left: 2px solid var(--purplePrimary);
}

.message-list-element:hover {
  background-color: var(--neutral50);
}
