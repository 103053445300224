.dg-input-group {
  display: flex;
  flex-direction: column;
}

.dg-input-header {
  font-family: "Planc";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #120e16;
}

.dg-input-control {
  background: transparent !important;
  border: 1px solid #c2becc !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 40px;
  align-items: center;
  gap: 12px;
  stroke: rgb(62 31 95 / 50%);
}

.dg-input-control-focus {
  border: 1px solid var(--purplePrimary) !important;
  background: transparent !important;
  border-radius: 4px !important;
  width: 100% !important;
  box-shadow: 0 0 0 0.2rem rgb(61 25 100 / 25%) !important;
  height: 40px;
  align-items: center;
  gap: 12px;
  stroke: var(--purplePrimary);
}

.dg-input-area {
  background: transparent !important;
  border: 1px solid #c2becc !important;
  border-radius: 4px !important;
  width: 100% !important;
  padding: 10px 20px !important;
}

.dg-input-area:focus {
  border: 1px solid var(--purplePrimary) !important;
  box-shadow: 0 0 0 0.2rem rgb(61 25 100 / 25%) !important;
}
